import React, { useEffect } from "react";

import Seo from "components/Seo";
import Layout from "components/Layout";
import { Box, Typography } from "@mui/material";
import { useRouter } from "next/router";

function Custom404() {
  const route = useRouter();
  useEffect(() => {
    route.push("/");
  }, []);

  return (
    <Layout>
      <Seo
        title={"404 - Expander"}
        description="WYSZUKIWARKA NIERUCHOMOŚCI Sprawdź bezpłatnie ceny 10 tys. mieszkań"
      />
      <Box
        p={10}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h2">Nie odnaleziono zawartości</Typography>
        <Typography variant="h3">Status: 404</Typography>
      </Box>
    </Layout>
  );
}

export default Custom404;
